// Libs
import React, { Component } from 'react';

// Components
import Loader from 'components/reusable/Loader';

// Services
import OnlineBookingService from 'services/OnlineBookingService';
import BootboxHelper from 'helpers/BootboxHelper';

class EvoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            cardDetails: '',
            acceptTerms: false,
            saveCardDetails: false,
            isComplete: false
        };

        this.submit = this.submit.bind(this);
    }

    async componentDidMount() {
        const isPaymentAlreadyMade = await OnlineBookingService.isPaymentAlreadyMade();
        if (isPaymentAlreadyMade) {
            this.props.onComplete();
        }

        this.load();
        this.setState({ isLoading: false });
    }

    async load() {
        const {
            savedCard
        } = this.props;

        if (!savedCard || (savedCard && savedCard.paymentProvider != 'evo')) {
            if (!this.state.acceptTerms) {
                BootboxHelper.alert('Please tick to agree Terms and Conditions before proceeding.');
            }
        }
    }

    async renderForm(acceptTerms) {
        this.setState({ acceptTerms });
        if (acceptTerms && !this.props.savedCard) {
            this.showPaymentForm();
        }
    }

    async showPaymentForm() {
        const { depositAmount, customer, booking } = this.props;
        const amount = depositAmount != null ? depositAmount : booking.depositAmount;
        const customerID = customer ? customer.customerID : 0;
        const { isComplete } = this.state;
        this.props.setIsPaymentPageLoading(true);

        const paymentInfo = await OnlineBookingService.initDepositPaymentByEvo(
            amount, customerID
        );
        var cashier = window.com.myriadpayments.api.cashier();
        cashier.init({
            baseUrl: paymentInfo.validatedTokenDetails.cashierUrl,
            bannerUrl: " "
        });
        cashier.show({
            containerId: "payment-iframe",
            merchantId: paymentInfo.validatedTokenDetails.merchantId,
            token: paymentInfo.validatedTokenDetails.token,
            paymentSolutionId: paymentInfo.validatedTokenDetails.paymentSolutionId,
            successCallback: () => {
                if (!isComplete)
                    this.onSucess(paymentInfo.validatedTokenDetails)
            },
            failureCallback: () => this.onFailure(paymentInfo.validatedTokenDetails),
            cancelCallback: () => JSON.stringify(paymentInfo.validatedTokenDetails)
        });
        this.props.setIsPaymentPageLoading(false);
    }

    async onSucess(tokenDetails) {
        this.setState({
            isComplete: true
        })
        const { customer } = this.props;
        const customerID = customer ? customer.customerID : 0;
        const { saveCardDetails } = this.state;
        this.checkForResponseInterval = setInterval(async () => {
            const { isSuccess, error } = await OnlineBookingService.getResponsePostTransaction(
                saveCardDetails, customerID
            );
            if (isSuccess || error) {
                clearInterval(this.checkForResponseInterval);
                this.props.setIsBusy(false);
                if (isSuccess) {
                    this.props.onComplete();
                } else {
                    this.props.onError(error);
                    this.showPaymentForm();
                }
            }
        }, 250);
    }

    async onFailure(tokenDetails) {
        const { saveCardDetails } = this.state;
        const { customer } = this.props;
        const customerID = customer ? customer.customerID : 0;
        const response = await OnlineBookingService.getResponsePostTransaction(
            saveCardDetails, customerID
        );
        if (response == 'DECLINED' || response == 'ERROR') {
            this.props.onError('Card Declined! Please try again.');
            this.props.setIsBusy(false);
            this.showPaymentForm();
        }
    }

    disableCheckBox() {
        const {
            acceptTerms
        } = this.state;

        if (acceptTerms)
            return true;
        else
            return false;
    }

    async submit(e) {
        const { savedCard, acceptTermsConditions, proceedWithSavedCard, booking, depositAmount, customer } = this.props;

        if (!acceptTermsConditions) {
            this.props.onError('Please tick to confirm that you agree to the Terms and Conditions');
            return;
        }

        if (savedCard && !proceedWithSavedCard) {
            this.props.onError('Please confirm to continue with saved card');
            return;
        }
        this.props.setIsBusy(true);
        var amount = booking ? booking.depositAmount : depositAmount;
        var customerID = customer ? customer.customerID : 0;
        const response = await OnlineBookingService.initDepositPaymentBySavedCardEvo(
            amount, customerID
        );
        if (response.cardStatus == 'CAPTURED') {
            this.props.setIsBusy(false);
            this.props.onComplete();
        }
        else if (response.cardStatus == 'DECLINED' || response.cardStatus == 'ERROR') {
            this.props.onError('Card Declined! Please try again.');
            this.props.setIsBusy(false);
        }
    }

    render() {
        const {
            render,
            salonInfo,
            savedCard,
            booking,
            depositAmount
        } = this.props;
        const {
            isLoading,
            acceptTerms,
            saveCardDetails
        } = this.state;

        var amount = booking ? booking.depositAmount : depositAmount;

        if (isLoading) {
            return (<Loader />);
        }
        return (
            render((
                <>
                    <p className="agree-terms-conditions">
                        <input
                            id="agree-tcs"
                            type="checkbox"
                            checked={acceptTerms}
                            disabled={this.disableCheckBox()}
                            onChange={e => this.renderForm(e.target.checked)}
                        />
                        <span>
                            <label htmlFor="agree-tcs">
                                I agree to the
                            </label>
                            {' '}<a href={salonInfo.termsConditionsLink} target="_blank">Terms and Conditions</a>
                        </span>

                        {!savedCard &&
                            <p className="save-card-details">
                                <input id="save-card"
                                    type="checkbox"
                                    checked={saveCardDetails || false}
                                    onChange={e => this.setState({ saveCardDetails: e.target.checked })} />
                                <span>
                                    <label htmlFor="save-card">
                                        Save card details for future payments
                                    </label>
                                </span>
                            </p>
                        }
                    </p>
                    {acceptTerms && !savedCard &&
                        <div className="col-md-12">
                            <div name="payment-iframe" id="payment-iframe" className="payment-iframe"></div>
                        </div>
                    }
                </>
            ), e => this.submit(e))
        );
    }
}
export default EvoForm;